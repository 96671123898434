<template>
	<v-container fluid>
		<v-card-text class="px-1 mt-5">
			<v-row no-gutters>
				<v-col cols="12">
					<p class="ma-0 label mb-4">
						Select banner image for media:
					</p>

					<FileUploader
						:initFiles="banner ? [banner] : []"
						@errorOccured="dropzoneError"
						:purpose="`banner`"
						:maxFiles="1"
						@changed="fileUploaderChangedBanner"
					/>
				</v-col>
				<v-col cols="12">
					<p class="ma-0 label mb-4">Title:</p>
					<v-text-field
						solo
						dense
						flat
						outlined
						v-model="title"
						placeholder="Enter Title"
						:error-messages="errors.title"
					></v-text-field>
				</v-col>
				<v-col cols="12">
					<p class="ma-0 label mb-5">Description:</p>
					<v-textarea
						solo
						flat
						dense
						name="description"
						no-resize
						outlined
						v-model="description"
						placeholder="Enter Description"
						:error-messages="errors.description"
					></v-textarea>
				</v-col>
				<v-col cols="12">
					<p class="ma-0 label mb-4">Select logo for Publisher:</p>

					<FileUploader
						:initFiles="publisherLogo ? [publisherLogo] : []"
						@errorOccured="dropzoneError"
						:purpose="`publisher-logo`"
						:maxFiles="1"
						@changed="fileUploaderChangedLogo"
					/>
				</v-col>
				<v-col cols="12">
					<v-menu
						ref="menu"
						v-model="menu"
						:close-on-content-click="false"
						:return-value.sync="datePublished"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<p class="ma-0 label">Published date:</p>
							<v-text-field
								placeholder="Select Media Published Date"
								v-model="datePublished"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="datePublished"
							no-title
							scrollable
						>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="menu = false">
								Cancel
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="$refs.menu.save(datePublished)"
							>
								OK
							</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" class="mb-5">
					<p class="ma-0 label mb-5">External Site Link:</p>
					<v-text-field
						solo
						flat
						outlined
						dense
						v-model="externalSiteLink"
						placeholder="Enter Link to external site"
						:error-messages="errors.externalSiteLink"
					></v-text-field>
				</v-col>
			</v-row>
		</v-card-text>
		<div class="footer-container">
			<v-footer absolute class="footer">
				<v-btn @click="saveMediaCoverage()" depressed class="save"
					>Save</v-btn
				></v-footer
			>
		</div>
	</v-container>
</template>
<script>
import FileUploader from '@/components/FileUploader'
export default {
	components: { FileUploader },
	props: {
		mediaCoverage: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			menu: false,
			modal: false,
			menu2: false,
			banner: null,
			bannerId: null,
			publisherLogo: null,
			publisherLogoId: null,
			purpose: '',
			loading: false,
			mediaCoverageId: null,
			isEdit: false,
			title: '',
			description: '',
			datePublished: '',
			externalSiteLink: '',
			errors: {
				title: '',
				description: '',
				externalSiteLink: '',
			},
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	watch: {
		title() {
			this.errors.title = ''
		},
		description() {
			this.errors.description = ''
		},
	},
	created() {
		if (Object.keys(this.mediaCoverage).length > 0) {
			this.banner =
				this.mediaCoverage.banner && this.mediaCoverage.banner.media
					? this.mediaCoverage.banner
					: null
			this.bannerId =
				this.mediaCoverage.banner && this.mediaCoverage.banner.media
					? this.mediaCoverage.banner.media
					: null
			this.title = this.mediaCoverage.title
			this.description = this.mediaCoverage.description
			this.publisherLogo =
				this.mediaCoverage.publisherLogo &&
				this.mediaCoverage.publisherLogo.media
					? this.mediaCoverage.publisherLogo
					: null
			this.publisherLogoId =
				this.mediaCoverage.publisherLogo &&
				this.mediaCoverage.publisherLogo.media
					? this.mediaCoverage.publisherLogo.media
					: null
			this.datePublished = this.mediaCoverage.datePublished
			this.mediaCoverageId = this.mediaCoverage.id
			this.externalSiteLink = this.mediaCoverage.externalSiteLink
		}
	},
	methods: {
		fileUploaderChangedBanner(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					media: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		fileUploaderChangedLogo(val) {
			if (val.length != 0) {
				const data = val[0]
				this.publisherLogoId = data.id
				this.purpose = 'publisher-logo'
				this.publisherLogo = {
					id: this.publisherLogoId,
					media: this.publisherLogoId,
					publicURL: data.publicURL,
				}
			} else {
				this.publisherLogoId = null
				this.publisherLogo = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		saveMediaCoverage() {
			let mediaCoverage = {
				banner: this.banner,
				title: this.title,
				description: this.description,
				publisherLogo: this.publisherLogo,
				datePublished: this.datePublished,
				externalSiteLink: this.externalSiteLink,
				id: this.mediaCoverageId,
			}
			this.$emit('save-mediaCoverage', mediaCoverage)
		},
	},
}
</script>
<style scoped>
.label {
	font-size: 1.1rem;
}
.headline {
	color: #303f9f !important;
	font-size: 24px !important;
}
.underline {
	border-bottom: 3px solid #8bc34a;
	margin-top: 10px;
	width: 40px;
	display: block;
}
.footer {
	background-color: var(--blue);
	height: 50px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
</style>
