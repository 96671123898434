<template>
	<v-container v-if="$can('view', 'Page')" fluid>
		<div class="mb-4 text-right">
			<v-btn depressed class="add" @click="addMediaCoverage()">
				+ Add Media Coverage
			</v-btn>
		</div>

		<v-card v-if="mediaCoverages.length > 0" class="py-6 px-6">
			<draggable
				v-model="mediaCoverages"
				class="drag-area"
				ghost-class="ghost"
			>
				<transition-group name="sortable">
					<MediaCoverageListing
						v-for="mediaCoverage in mediaCoverages"
						:key="mediaCoverage.id"
						:mediaCoverage="mediaCoverage"
						@edit-media="editMediaCoverage($event)"
						@remove-media="removeMediaCoverage($event)"
					></MediaCoverageListing>
				</transition-group>
			</draggable>

			<v-navigation-drawer
				v-model="drawer"
				fixed
				stateless
				temporary
				right
				class="media-drawer"
				v-if="showCreateMediaCoverage"
			>
				<div class="d-flex align-center header pt-2 px-2 pb-2">
					<span
						class="mdi mdi-chevron-left back-icon"
						@click="closeDrawer()"
					></span>
					<h1 class="crud-title ml-2">Add Media Coverage</h1>
					<span
						class="mdi mdi-close back-icon"
						@click="closeDrawer()"
					></span>
				</div>
				<CreateMediaCoverage
					:mediaCoverage="mediaCoverage"
					@save-mediaCoverage="updateMediaCoverages($event)"
					class="px-4"
				></CreateMediaCoverage>
			</v-navigation-drawer>
		</v-card>
		<NoData v-else></NoData>
	</v-container>
</template>

<script>
import draggable from 'vuedraggable'
import CreateMediaCoverage from './components/CreateMediaCoverage'
import MediaCoverageListing from './components/MediaCoverageListing'
import NoData from '../../../components/NoData'
import { v4 as uuidv4 } from 'uuid'
export default {
	data() {
		return {
			drawer: true,
			showCreateMediaCoverage: false,
			mediaCoverages: [],
			mediaCoverage: {},
		}
	},
	components: {
		draggable,
		CreateMediaCoverage,
		MediaCoverageListing,
		NoData,
	},
	created() {
		this.getMediaCoverageData()
	},

	methods: {
		addMediaCoverage() {
			this.mediaCoverage = {
				banner: '',
				title: '',
				description: '',
				datePublished: '',
				externalSiteLink: '',
				publisherLogo: '',
				id: uuidv4(),
			}
			let arr = this.mediaCoverages
			arr.push(this.mediaCoverage)
			this.mediaCoverages = arr
			this.showCreateMediaCoverage = true
		},
		updateMediaCoverages(emittedObj) {
			let mediaCoverages = this.mediaCoverages
			mediaCoverages.every(function (mediaCoverage, index) {
				if (mediaCoverage.id === emittedObj.id) {
					let obj = {
						banner: emittedObj.banner ? emittedObj.banner : null,
						publisherLogo: emittedObj.publisherLogo
							? emittedObj.publisherLogo
							: null,
						title: emittedObj.title,
						description: emittedObj.description,
						datePublished: emittedObj.datePublished,
						externalSiteLink: emittedObj.externalSiteLink,
						id: emittedObj.id,
					}
					mediaCoverages[index] = obj
					// vm.$set(vm.facilities, index, obj);
					return false // to break the loop
				} else {
					return true
				}
			})
			this.mediaCoverages = mediaCoverages
			this.showCreateMediaCoverage = false
			this.saveMediaCoverage()
		},
		removeMediaCoverage(mediaId) {
			let arr = this.mediaCoverages
			arr.forEach((media, index) => {
				if (media.id === mediaId) {
					arr.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.mediaCoverages = arr
			this.saveMediaCoverage()
		},
		editMediaCoverage(mediaCoverage) {
			this.showCreateMediaCoverage = true
			this.mediaCoverage = mediaCoverage
		},
		getMediaCoverageData() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/pages/media-coverage`,
			}).then((response) => {
				let mediaCoverages = response.data.meta.mediaCoverages

				this.mediaCoverages = mediaCoverages.map((mediaCoverage) => ({
					banner: mediaCoverage.banner ? mediaCoverage.banner : null,
					title: mediaCoverage.title,
					description: mediaCoverage.description,
					publisherLogo: mediaCoverage.publisherLogo
						? mediaCoverage.publisherLogo
						: null,
					datePublished: mediaCoverage.datePublished,
					externalSiteLink: mediaCoverage.externalSiteLink,

					id: uuidv4(),
				}))
			})
		},
		saveMediaCoverage() {
			this.loading = true
			const projectId = this.$store.state.app.projectId

			let payload = {
				mediaCoverages: this.mediaCoverages.map((mediaCoverage) => ({
					banner: mediaCoverage.banner
						? mediaCoverage.banner.media
						: null,
					publisherLogo: mediaCoverage.publisherLogo
						? mediaCoverage.publisherLogo.media
						: null,
					title: mediaCoverage.title,
					description: mediaCoverage.description,

					datePublished: mediaCoverage.datePublished,
					externalSiteLink: mediaCoverage.externalSiteLink,
				})),
			}

			this.axios({
				method: 'put',
				url: `/${projectId}/pages/media-coverage`,
				data: payload,
			})
				.then((res) => {
					let mediaCoverages = res.data.data.meta.mediaCoverages
					this.mediaCoverages = mediaCoverages.map(
						(mediaCoverage) => ({
							banner: mediaCoverage.banner,
							title: mediaCoverage.title,
							description: mediaCoverage.description,
							publisherLogo: mediaCoverage.publisherLogo,
							datePublished: mediaCoverage.datePublished,
							externalSiteLink: mediaCoverage.externalSiteLink,
							id: uuidv4(),
						})
					)
					this.loading = false
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.loading = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		closeDrawer() {
			this.showCreateMediaCoverage = !this.showCreateMediaCoverage
			if (this.mediaCoverage.title === '') {
				this.removeMediaCoverage(this.mediaCoverage.id)
			}
		},
	},
}
</script>

<style scoped>
.media-drawer {
	width: 450px !important;
}
.add {
	background-color: var(--blue) !important;
	color: white;
}
.date {
	font-size: 15px;
	font-weight: 400;
	color: #808080eb;
}
.crud-title {
	width: 100% !important;
}
.back-icon {
	font-size: 25px;
	cursor: pointer;
}
</style>
